import * as React from "react";
import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
import { Theme } from "@material-ui/core";

import MuiTypography from "@material-ui/core/Typography";
import MuiGrid from "@material-ui/core/Grid";
import MuiLink from "@material-ui/core/Link";

const useStyles = makeStyles<Theme>(theme => {
  return createStyles({
    root: {
      display: "flex",
      justify: "space-between",
      alignItems: "center",
      flexWrap: "wrap",
      backgroundColor: '#ffffff40',
      padding: '20px 10px',
      borderRadius: '5px',
      margin: "20px 0",
    },
    block: {
      minWidth: 64,
      padding: 8,
      backgroundColor: "#bcaaa4",
      borderRadius: 4,

      display: "flex",
      flexDirection: "column",
      justify: "center",
      alignItems: "center",

      "& > span": {
        color: "#efebe9"
      },
      "& > h6": {
        color: "white"
      }
    }
  });
});

interface HeaderProps {
  step: number;
  score: number;
  bestScore: number;
}
const Header: React.FC<HeaderProps> = ({ step, score, bestScore }) => {
  const classes = useStyles({});

  const Block = React.memo(
    ({ title, value }: { title: string; value: number }) => {
      return (
        <div className={classes.block}>
          <MuiTypography variant="caption">{title}</MuiTypography>
          <MuiTypography variant="h6">{value}</MuiTypography>
        </div>
      );
    }
  );

  return (
    <div className={`${classes.root} my-footer`}>
      <MuiGrid container className="header-info" wrap="nowrap">
        <div className="header-privacy ">
         <a href="./privacy.html" className="header-privacy__link makeStyles-block-5">Privacy Policy</a>
         <a href="./about.html" className="header-privacy__link makeStyles-block-5">About Game</a>
        </div>
   
        <div className="header-score">
          <MuiGrid item>
            <Block title="Step" value={step} />
          </MuiGrid>
          <MuiGrid item>
            <Block title="Score" value={score} />
          </MuiGrid>
          <MuiGrid item>
            <Block title="Best" value={bestScore} />
          </MuiGrid>
        </div>
      </MuiGrid>
    </div>
  );
};

export default React.memo(Header);
